<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />

        <div class="main-con">
            <div class="mat-info-con" style="justify-content: space-between; align-items: center">
                <div class="plant-dd" id="filters" style="width: 50%; display: flex">
                    <div class="plant-dd" style="margin-left: 0px">
                        <label for="committed_year" class="form-label select-lable lable-text"> Committed Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committedYear"
                            v-model="committedYr" @change="getDataForOEEReport()">
                            <option v-for="year in committedYear" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="pillar" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                            :value="plantSelectedInAsmtHome" @change="plantChanged($event)">
                            <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
                                {{ plant.plantName + ", " + plant.regionCode }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="plant-dd" id="filters" style="width: 50%; display: flex">
                    <div class="plant-dd" style="margin-left: 3rem">
                        <label for="month" class="form-label select-lable lable-text"> Month </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="month"
                            v-model="selectedMonth" @change="getDataForOEEReport()">
                            <option v-for="eachMonth in monthsList" v-bind:key="eachMonth.monthId"
                                :value="eachMonth.monthId">
                                {{ eachMonth.monthName }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="button-dd">
                    <div class="download-view action-btn" @click="downloadPdf">
                        <img alt="download" src="../../../assets/download_icon.svg" />
                    </div>
                </div>
            </div>

            <div class="mat-info-con" style="flex-wrap: wrap; padding: 2rem 2rem 2rem 0"
                v-for="(graphData, index) in graphDatas" :key="index">
                <div class="graph-div">
                    <div :id="'oee-graph-div-' + index" style="width: 50%">
                        <div class="main-tab-act default-font-color" style="padding: 0 2rem">
                            <div>
                                {{
                                    `${plantId.plantName} - ${graphData.constraintGroupName} (${graphData.groupType}) -
                                                                OEE`
}}
                            </div>
                            <div style="font-size: 1.13rem">
                                {{ `${monthsList[selectedMonth - 1].monthName} ${committedYr}` }}
                            </div>
                        </div>

                        <div>
                            <canvas height="350" :id="'oee-graph-' + index" style="background-color: white"></canvas>
                        </div>
                        <div class="graph-1-container">
                            <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                <div
                                    style="background-color: #109b10; margin: 0 0.5rem; width: 1rem; height: 1rem; border-radius: 1rem">
                                </div>
                                OEE1
                            </div>
                            <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                <div
                                    style="margin: 0 0.5rem; background-color: #ffa52c; width: 1rem; height: 1rem; border-radius: 1rem">
                                </div>
                                OEE2
                            </div>
                            <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                <div style="margin: 0 0.5rem">
                                    <img alt="icon" src="@/assets/oee1.svg" srcset="" />
                                </div>
                                OEE1
                            </div>
                            <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                <div style="margin: 0 0.5rem">
                                    <img alt="icon" src="@/assets/oee2.svg" srcset="" />
                                </div>
                                OEE2
                            </div>
                            <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                <div style="margin: 0 0.5rem">
                                    <img alt="icon" src="@/assets/oeegoal.svg" srcset="" />
                                </div>
                                OEE Goal
                            </div>
                        </div>
                    </div>
                    <div :id="'loss-graph-div-' + index" style="width: 50%">
                        <div class="main-tab-act default-font-color" style="padding: 0 2rem">
                            <div>
                                {{
                                    `${plantId.plantName} - ${graphData.constraintGroupName} (${graphData.groupType}) -
                                                                OEE Loss Walk `
                                }}
                            </div>
                            <div style="font-size: 1.13rem">
                                {{ `${monthsList[selectedMonth - 1].monthName} ${committedYr}` }}
                            </div>
                        </div>
                        <div>
                            <canvas height="350" :id="'loss-walk-' + index" style="background-color: white"></canvas>
                        </div>
                        <div v-if="graphData.hasOwnProperty('oeeConstraint')" style="flex-wrap: wrap; padding: 0px 0 0 2rem"
                            class="graph-2-container">
                            <div class="" style="text-align: center; width: 16.6%">
                                <div>Schedule Rate</div>
                                <div class="value-font">{{ graphData.oeeConstraint.sr }}%</div>
                            </div>
                            <div class="" style="text-align: center; width: 16.6%">
                                <div>Available Rate</div>
                                <div class="value-font">{{ graphData.oeeConstraint.ar }}%</div>
                            </div>
                            <div class="" style="text-align: center; width: 16.6%">
                                <div>Performance Rate</div>
                                <div class="value-font">{{ graphData.oeeConstraint.pr }}%</div>
                            </div>
                            <div class="" style="text-align: center; width: 16.6%">
                                <div>Quality Rate</div>
                                <div class="value-font">{{ graphData.oeeConstraint.qr }}%</div>
                            </div>
                            <div style="text-align: center; width: 16.6%">
                                <div>OEE1</div>
                                <div class="value-font">{{ graphData.oeeConstraint.oeE1 }}%</div>
                            </div>
                            <div style="text-align: center; width: 16.6%">
                                <div>OEE2</div>
                                <div class="value-font">{{ graphData.oeeConstraint.oeE2 }}%</div>
                            </div>
                            <div class="graph-div" style="align-items: center; width: 40%; justify-content: center">
                                YTD Completed DEC3/RE2 RPL Count :
                                <div class="value-font">{{ graphData.oeeConstraint.rplDecReCount }}</div>
                            </div>
                            <div class="graph-div" style="align-items: center; width: 40%; justify-content: center">
                                Total Number of Machines :
                                <div class="value-font">{{ graphData.oeeConstraint.totalNoOfMachines }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mat-info-con"
                style="flex-wrap: wrap; padding: 2rem 2rem 2rem 0; justify-content: center; align-content: center"
                v-if="!graphDatas.length">
                <div class="no-data">
                    <img alt="no data" src="../../../assets/No_Data.svg" />
                    <span class="ed-date">No data found</span>
                </div>
            </div>
            <div class="" style="width: 100%">
                <div class="main-tab-act default-font-color" style="width: inherit; padding: 0 2rem">How to Interpret
                    OEE</div>
                <img alt="reference img" src="@/assets/oee_report_bottom.jpg" style="width: inherit" srcset="" />
            </div>
        </div>
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import { mapGetters } from "vuex";
import Chart from "chart.js/auto";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import colorCodes from "@/common/constants/colorCodes";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import SpiderChartReport from "@/common/helper/SpiderChartReport.vue";

export default {
    name: "MachineOEEReport",
    data() {
        return {
            showLoader: true,
            committedYear: [],
            graphDatas: [],
            OEEReportsData: [],
            plantList: [],
            committedYears: [],
            plantId: 1,
            printData: [],
            committedYr: new Date().getFullYear(),
            selectedMonth: new Date().getMonth() + 1,
            monthsList: [
                { monthId: 1, monthName: "Jan" },
                { monthId: 2, monthName: "Feb" },
                { monthId: 3, monthName: "Mar" },
                { monthId: 4, monthName: "Apr" },
                { monthId: 5, monthName: "May" },
                { monthId: 6, monthName: "Jun" },
                { monthId: 7, monthName: "Jul" },
                { monthId: 8, monthName: "Aug" },
                { monthId: 9, monthName: "Sep" },
                { monthId: 10, monthName: "Oct" },
                { monthId: 11, monthName: "Nov" },
                { monthId: 12, monthName: "Dec" }
            ],
            OEEGraph1Template: {
                type: "bar",
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: "Prev OEE 1",
                            data: [],
                            type: "bar",
                            borderRadius: Number.MAX_VALUE,
                            borderSkipped: false,
                            datalabels: {
                                rotation: "270",
                                //  align: "center",
                                // anchor: "center",
                                align: "end",
                                anchor: "end",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 12
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            backgroundColor: ["#109B10"],
                            borderColor: ["#000", "#000", "#000", "#000", "#000", "000", "#000", "#000", "#000"],
                            borderWidth: 1,
                            barPercentage: 0.8
                        },
                        {
                            label: "Prev OEE 2",
                            data: [],
                            type: "bar",
                            borderRadius: Number.MAX_VALUE,
                            borderSkipped: false,
                            datalabels: {
                                rotation: "270",
                                //  align: "center",
                                // anchor: "center",
                                align: "end",
                                anchor: "end",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 12
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            backgroundColor: ["#FFA52C"],
                            borderColor: ["#000", "#000", "#000", "#000", "#000", "000", "#000", "#000", "#000"],
                            borderWidth: 1,
                            barPercentage: 0.8
                        },
                        {
                            label: "OEE 1",
                            type: "line",

                            animation: true,
                            data: [],
                            datalabels: {
                                align: "right",
                                anchor: "center",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 12
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            radius: 3,
                            backgroundColor: "#109B10",
                            borderColor: "#109B10"
                        },
                        {
                            label: "OEE 2",
                            type: "line",

                            animation: true,
                            data: [],
                            datalabels: {
                                align: "end",
                                anchor: "end",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 10
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            radius: 5,
                            backgroundColor: "#F1AA1D",
                            borderColor: "#F1AA1D",
                            pointStyle: "star"
                        },
                        {
                            label: "OEE Goal",
                            type: "line",

                            animation: true,
                            data: [],
                            datalabels: {
                                align: "left",
                                anchor: "center",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 10
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            radius: 5,
                            backgroundColor: "#61559B",
                            borderColor: "#61559B",
                            pointStyle: "cross"
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    lineTension: 0,
                    elements: {
                        bar: {
                            // hoverBackgroundColor:'yellow'
                        }
                    },
                    scales: {
                        y: {
                            display: true,
                            title: {
                                display: true,
                                text: "Percentage",
                                padding: { top: 30, left: 0, right: 0, bottom: 0 },
                                fontStyle: "bold",
                                fontSize: 16,
                                fontColor: "#333"
                            }
                        },
                        x: {
                            grid: {
                                display: false,
                                drawBorder: false,
                                drawOnChartArea: false,
                                drawTicks: false
                            }
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: ""
                        },
                        legend: {
                            display: false
                        },
                        datalabels: {
                            formatter: (val) => `${val}`
                        }
                    }
                }
            },
            OEEGraph2Template: {
                type: "bar",
                data: {
                    labels: [
                        "Total time",
                        ["Schedule", "Loss"],
                        ["Scheduled Time", "(SR)"],
                        ["Available", "Loss"],
                        ["Available Time", "(as of 100%)"],
                        ["Performance", "Loss"],
                        ["Operating Time", "(as of 100%)"],
                        ["Quality", "Loss"],
                        ["Effective Time", "OEE1"]
                    ],
                    datasets: [
                        {
                            label: "",
                            data: [100, [100, 94], 94, [95, 88], 88, [88, 84], 84, [84, 81], 81],
                            type: "bar",
                            borderRadius: Number.MAX_VALUE,
                            borderSkipped: false,
                            datalabels: {
                                align: "end",
                                anchor: "end",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 10
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            backgroundColor: [
                                colorCodes.TotalTime,
                                colorCodes.Loss,
                                colorCodes.STValue,
                                colorCodes.Loss,
                                colorCodes.ATValue,
                                colorCodes.Loss,
                                colorCodes.OTValue,
                                colorCodes.Loss,
                                colorCodes.EFValue
                            ],
                            borderColor: "#000",
                            borderWidth: 1,
                            barPercentage: 0.3
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    lineTension: 0,
                    elements: {
                        bar: {
                            // hoverBackgroundColor:'yellow'
                        }
                    },
                    scales: {
                        y: {
                            stacked: true,
                            display: true,
                            title: {
                                display: true,
                                text: "Percentage",
                                padding: { top: 30, left: 0, right: 0, bottom: 0 },
                                fontStyle: "bold",
                                fontSize: 16,
                                fontColor: "#333"
                            }
                        },
                        x: {
                            stacked: true,
                            grid: {
                                display: false,
                                drawBorder: false,
                                drawOnChartArea: false,
                                drawTicks: false
                            }
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: ""
                        },
                        legend: {
                            display: false
                        },
                        datalabels: {}
                    }
                }
            }
        };
    },

    components: {
        Breadcrumb,
        Loader
    },

    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 3,
            subMenu: 5
        });
        this.$store.dispatch("breadcrumb", {
            title: "Constraint Group OEE Report",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Reports / ", primary: true, redirect: "/" },
                { name: "Constraint Group OEE Report", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        console.log(this.gapClosureData);
        this.getCommittedYear();
    },
    mixins: [SpiderChartReport],
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData"])
    },
    methods: {
        downloadPdf() {
            this.showLoader = true;
            this.pdfMakeCall("oee", this.printData);
            setTimeout(() => {
                this.showLoader = false;
            }, 10000);
        },
        getCommittedYear() {
            ApiCalls.httpCallDetails(Api.GETOEEDASHBOARDYEAR, "get").then((data) => {
                this.committedYear = data.data;
                this.getPlantDetails();
            });
        },
        getPlantDetails() {
            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.getDataForOEEReport();
            });
        },
        plantChanged(event) {
            console.log("plantChanged");
            this.showLoader = true;
            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
            this.getDataForOEEReport();
        },
        getDataForOEEReport() {
            this.graphDatas = [];
            this.showLoader = true;
            ApiCalls.httpCallDetails(`${Api.GETOEEREPORT}/${this.plantId.plantId}/${this.committedYr}/${this.selectedMonth}`, "get").then((data) => {
                console.log(data);
                data.data.sort((a, b) => {
                    if (a.groupType < b.groupType) return -1;
                    else return a.groupType === b.groupType ? 0 : 1
                })
                this.graphDatas = data.data;
                this.printData = [];
                data.data.forEach((data, pos) => {
                    this.printData.push("oee-graph-div-" + pos);
                    this.printData.push("loss-graph-div-" + pos);
                });
                setTimeout(() => {
                    this.renderGraph();
                }, 1000);
            });
        },
        formateData(val, context) {
            return (typeof val === "number" ? val : Math.round((val[0] - val[1]) * 100) / 100) + "%";
        },
        renderGraph() {
            this.graphDatas.forEach((type, pos) => {
                type = {
                    ...type,
                    oee1: Math.round(((type.reportLoss.mainComp[2] * type.reportLoss.mainComp[4] * type.reportLoss.mainComp[6] * type.reportLoss.mainComp[8]) / 1000000) * 100) / 100,
                    oee2: Math.round(((type.reportLoss.mainComp[4] * type.reportLoss.mainComp[6] * type.reportLoss.mainComp[8]) / 10000) * 100) / 100,
                    sr: type.reportLoss.mainComp[2],
                    ar: type.reportLoss.mainComp[4],
                    pr: type.reportLoss.mainComp[6],
                    qr: type.reportLoss.mainComp[8]
                };
                let g1 = JSON.parse(JSON.stringify(this.OEEGraph1Template));
                g1.data.labels = [...type.reportOEE.labels, null];
                g1.data.datasets[0].data = type.reportOEE.yearWiseOEE1;
                g1.data.datasets[1].data = type.reportOEE.yearWiseOEE2;
                g1.data.datasets[2].data = [null, null, null, null, ...type.reportOEE.oeE1];
                g1.data.datasets[3].data = [null, null, null, null, ...type.reportOEE.oeE2];
                g1.data.datasets[4].data = [null, null, null, null, ...type.reportOEE.oeeGoal];

                let g2 = JSON.parse(JSON.stringify(this.OEEGraph2Template));

                let composedData = [];
                composedData = type.reportLoss.mainComp.map((item, index) => {
                    if (index % 2 !== 0) return [type.reportLoss.mainComp[index - 1], type.reportLoss.mainComp[index + 1]];
                    else return item;
                });
                g2.data.datasets[0].data = composedData;
                g2.options.plugins.datalabels.formatter = (val, context) => this.formateData(val, context);

                Chart.register(ChartJsPluginDataLabels);
                let ctx1 = document.getElementById("oee-graph-" + pos);
                let obj1 = new Chart(ctx1, g1);
                let obj2 = new Chart(document.getElementById("loss-walk-" + pos), g2);
                obj1.draw();
                obj2.draw();
            });
            this.showLoader = false;
        }
    }
};
</script>

<style scoped>
.graph-div {
    width: 100%;
    display: flex;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

.mat-info-con {
    padding: 2rem;
    display: flex;
}

.button-dd {
    display: inline-flex;
}

.download-view {
    border-radius: 2rem;
    border: 1px solid #274ba7;
    margin-left: 1rem;
    font-size: 1.33rem;
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.download-view:hover {
    background: #e3e8f7;
}

.action-btn {
    border: 1px #274ba7 solid;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    cursor: pointer;
}

.plant-dd {
    width: 45%;
}

.graph-1-container {
    width: 100%;
    padding: 2rem;
    color: #505d6f;
    font: 1rem "BarlowR", sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.graph-2-container {
    width: 100%;
    padding: 1rem 2rem;
    color: #505d6f;
    font: 1rem "BarlowR", sans-serif;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.value-font {
    font: 1.16rem "BarlowSB", sans-serif;
    padding: 1rem 0;
}

.legends {
    margin: 0 0.5rem;
    border: 1px solid #d3d3d3;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
}

.ST-value {
    background-color: #ee90d2;
}

.AT-value {
    background-color: #ffa52c;
}

.OT-value {
    background-color: #109b10;
}

.EF-value {
    background-color: #f4f88c;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
